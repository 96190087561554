import { FC, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, SxProps } from "@mui/material";
import StandaloneDropdownArrow from "components/mlpaUiComponents/StandaloneDropdownArrow";
import styled from "@emotion/styled";
import ordinaryColors from "themes/ordinaryColors";

const StyledFormControl = styled(FormControl, {
  shouldForwardProp(propName) {
    return propName !== "hideBorder";
  },
})(({ hideBorder = false }: { hideBorder?: boolean }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    border: hideBorder ? "none" : undefined,
  },
  ".MuiOutlinedInput-input": {
    padding: 0,
    justifyItems: "center",
  },
  width: "fit-content",
  "& svg": {
    right: "10px",
    marginRight: "-10px",
    marginTop: "1px",
    color: ordinaryColors.functionalBlack,
  },
}));

interface Props {
  handleChange: (event: SelectChangeEvent) => void;
  hideBorder?: boolean;
  hideValue?: boolean;
  labelTitle?: string;
  options: { label: JSX.Element; value: string }[];
  sx?: SxProps;
  value: string;
}

const SelectDropDown: FC<Props> = ({
  handleChange,
  hideBorder = false,
  hideValue = false,
  labelTitle,
  options,
  sx,
  value,
}) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const currentTranslation = focused
    ? "translate(14px, -9px) scale(0.75)"
    : value
    ? "translate(14px, -9px) scale(0.75)"
    : " translate(14px, 8px) scale(1)";

  return (
    <StyledFormControl hideBorder={hideBorder}>
      {labelTitle && (
        <InputLabel id="demo-simple-select" aria-label={labelTitle} sx={{ transform: currentTranslation }}>
          {labelTitle}
        </InputLabel>
      )}
      <Select
        labelId="demo-simple-select"
        id="demo-simple-select"
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        sx={{ height: 40, ...sx }}
        SelectDisplayProps={{
          style: {
            color: hideValue ? "transparent" : "red",
            alignItems: "center",
            justifyItems: "center",
          },
        }}
        IconComponent={StandaloneDropdownArrow}
      >
        {options?.map(({ value, label }) => (
          <MenuItem key={value} value={value} sx={{ display: "flex", minWidth: "40px", minHeight: "40px" }}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default SelectDropDown;
