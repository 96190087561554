import { BullitTitle, LegalNoteLink, MailTo, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  alternativeDisputeResolution: "Alternative dispute resolution pursuant to Art. 14 para. 1 ODR-VO and § 36 VSBG:",
  alternativeDisputeResolutionContent: `
    The European Commission provides an online dispute resolution (ODR) platform, which can be found at ${LegalNoteLink(
      {
        url: "https://ec.europa.eu/consumers/odr",
        ariaLabel: "Link to",
        label: "https://ec.europa.eu/consumers/odr",
      }
    )}.
    ${nextLine()}We are neither obliged nor willing to participate in a dispute resolution procedure before a consumer arbitration body.
  `,
  disputeResolution: "Online Dispute Resolution",
  generalInformation: `
    ${BullitTitle({ title: "Address:", nextParagraph: false })}
    ${nextLine()}Oranienburger Str. 45
    ${nextLine()}10117 Berlin
    ${nextParagraph()}${BullitTitle({ title: "Phone:", nextParagraph: false })}
    + 49 (0) 30/ 30 10 15 05 -0
    ${nextParagraph()}${BullitTitle({ title: "EMail:", nextParagraph: false })}
    ${MailTo({ mailTo: "info@wind-fgw.de", label: "info@wind-fgw.de" })}
    ${nextParagraph()}${BullitTitle({ title: "Website:", nextParagraph: false })}
    ${LegalNoteLink({
      url: "https://www.wind-fgw.de",
      ariaLabel: "Link to",
      label: "www.wind-fgw.de",
    })}
    ${nextParagraph()}${BullitTitle({ title: "Chief Executive Officer:", nextParagraph: false })}
    Dipl.-Ing. Jan Liersch
    ${nextParagraph()}${BullitTitle({ title: "District Court:", nextParagraph: false })}
    Berlin
    ${nextParagraph()}${BullitTitle({ title: "Register of Associations", nextParagraph: false })}
    AG Charlottenburg, VR 29989 B
    ${nextParagraph()}${BullitTitle({ title: "USt-ID:", nextParagraph: false })}
    DE203774047
  `,
  header: "FGW e.V. - Fördergesellschaft Windenergie und andere Dezentrale Energien",
};
