/* eslint-disable import/no-unresolved */
import { Box, styled } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";
import theme from "themes/useCustomTheme";

export const mainContainerStyles = {
  height: "calc(100% - 850px)",
};

export const containerStyles = () => {
  return {
    [theme().breakpoints.between(1200, 1439)]: {
      padding: 0,
      // paddingLeft: "68px",
    },
    [theme().breakpoints.between(1440, 1639)]: {
      padding: 0,
      // paddingLeft: "68px",
    },
    [theme().breakpoints.up(1640)]: {
      padding: 0,
      // paddingLeft: "68px",
    },
  };
};

export const RootWrapperSC = styled("div")(
  ({ theme }) => `
    height: 100%;
`
);

export const titleStyles = {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "20px",
};

export const BreadcrumbBoxSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
        color: ´${ordinaryColors.fgwDeep}´;
        & span {
          font-size: 29px;
          font-style: normal;
          font-weight: 700;
          height: 44px;
          letter-spacing: 0.15px;
          line-height: 150%;
          margin-right: 32px;
          width: 62px;
        }
`
);
export const SimpleSearchSC = styled("div")(
  () => `
        display: flex;
        width: 268px;
        height: 36px;
        & input {
          padding: 5px 0px
        }
`
);

export const ToolboxSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
       width: 237px;
       justify-content: flex-end;
        
`
);

export const AlertSC = styled("div")(
  () => `
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        margin-right: 10px;
        & svg {
          cursor: pointer;
          align-self: center;
        }
`
);
export const LanguageSelectorSC = styled("div")(
  () => `
        display: flex;
        justify-izems: space-between;
        align-items: center;
        width: 70px;
        min-width: 50px;
        & svg {
          right: 10px;
          width: 24px
        }
`
);
export const LanguageDisplaySC = styled("span")({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150% /* 24px */",
  letterSpacing: "0.15px",
});

export const ToolsAndSearchSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
        width:500px;
        justify-content:flex-end;
`
);

export const HeaderSC = styled("div")(
  () => `
        align-items: center;
        background-color: ${ordinaryColors.functionalWhite};	
        border-bottom: 1px solid ${ordinaryColors.unnamed1};
        display: flex;
        justify-content: space-between;
`
);

export const HeaderBoxSC = styled("div")(
  () => `
        display: flex;
        flex-direction: column;
`
);

export const NavigationContainerSC = styled("div")(
  () => `
        height: calc(100% - 90px);
        position: absolute;
`
);

export const HeaderWrapper = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== "noPadding";
  },
})(({ noPadding = false }: { noPadding?: boolean }) => ({
  paddingLeft: noPadding ? 0 : "36px",
  paddingRight: noPadding ? 0 : "22px",
  minWidth: "40rem",
}));
