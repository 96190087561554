import { Box, SvgIcon } from "@mui/material";
import { ReactNode } from "react";
import {
  StyledFooterInfo,
  StyledFooterInfoBox,
  StyledFooterInfoBoxWrapper,
  StyledFooterInfoWrapper,
  StyledFooterInfoContentWrapper,
} from "./styles";

export interface ElementItem {
  icon?: React.ReactNode;
  text?: string;
  withLink?: { to: string; label: string };
}

export interface FooterInfoItems {
  headerTitle: ReactNode;
  content: Array<ElementItem>;
  width?: string;
}

export type FooterInfoBoxContentProps = Array<FooterInfoItems>;

export type FooterInfoBoxElementItemFooterInfoBoxContentProps = Omit<FooterInfoItems, "headerTitle">;

const FooterInfoBoxElementItem = ({ content, width }: FooterInfoBoxElementItemFooterInfoBoxContentProps) => (
  <StyledFooterInfoContentWrapper>
    {content.map((item, index) => (
      <StyledFooterInfo key={index} width={width}>
        {item.icon && item.text && <SvgIcon>{item.icon}</SvgIcon>}
        {item.icon && !item.text && <>{item.icon}</>}
        {!item.withLink && item.text}
        {item.withLink && (
          <Box flexDirection="column">
            <Box dangerouslySetInnerHTML={{ __html: item.text ?? "" }} />
            <Box>
              <a href={item.withLink.to}>{item.withLink.label}</a>
            </Box>
          </Box>
        )}
      </StyledFooterInfo>
    ))}
  </StyledFooterInfoContentWrapper>
);

const FooterInfoBoxElement = ({
  element,
}: {
  element: {
    headerTitle: ReactNode;
    content: Array<ElementItem>;
    width?: string;
    margin?: string | number;
  };
}) => {
  return (
    <StyledFooterInfoBox margin={element.margin}>
      <StyledFooterInfoWrapper fontWeight={600}>{element.headerTitle}</StyledFooterInfoWrapper>
      <StyledFooterInfoWrapper fontWeight={400}>
        <FooterInfoBoxElementItem content={element.content} width={element.width} />
      </StyledFooterInfoWrapper>
    </StyledFooterInfoBox>
  );
};

export const FooterInfoBoxContent = ({ content }: { content: FooterInfoBoxContentProps }) => (
  <StyledFooterInfoBoxWrapper role="contentinfo">
    {content.map((element, index) => (
      <FooterInfoBoxElement key={index} element={element} />
    ))}
  </StyledFooterInfoBoxWrapper>
);
