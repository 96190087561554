import { nextParagraph, LegalNoteLink, BullitList, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  faqSubTitle:
    "FAQs - Antworten auf häufig gestellte Fragen. Herzlich willkommen in unserem FAQ-Bereich. Hier finden Sie Antworten auf häufig gestellte Fragen zum ZEREZ-Register (Zentrales Register für Einheiten- und Komponentenzertifikate). Dieser FAQ-Bereich wird kontinuierlich weiterentwickelt. Melden Sie sich bei Fragen, Anregungen oder Wünschen gerne bei uns.",
  qAndA: {
    whatIsZerez: {
      q: "Was ist ZEREZ?",
      a: "ZEREZ steht für das Zentrale Register für Einheiten- und Komponentenzertifikate. Es handelt sich um eine umfassende Informationsplattform für die Erfassung und Bereitstellung von Zertifikaten im Kontext des Netzanschlusses von Energieerzeugungsanlagen.",
    },
    status: {
      q: "Was ist der aktuelle Status von ZEREZ?",
      a: `
    Im "
    ${LegalNoteLink({
      url: "https://www.bundestag.de/dokumente/textarchiv/2023/kw46-pa-klimaschutz-erneuerbare-energiengesetz-976490",
      ariaLabel: "Link zu",
      label: "Solarpaket I",
    })}" der Bundesregierung sind auch Regelungen zur Einführung, zur Erhaltung, zum Betrieb und zur Weiterentwicklung eines zentralen, über das Internet zugänglichen Registers zur Erfassung und Überwachung von Energieanlagen und Energieanlagenteilen enthalten. Diese werden durch die Bestimmungen der
    ${LegalNoteLink({
      url: "https://www.bundesrat.de/SharedDocs/drucksachen/2023/0401-0500/456-23.pdf?__blob=publicationFile&v=2",
      ariaLabel: "Link zu",
      label: "Verordnung zur Änderung der Elektrotechnische-Eigenschaften-Nachweis-Verordnung",
    })} Verordnung zur Änderung der Elektrotechnische-Eigenschaften-Nachweis-Verordnung (NELEV-Änderungsverordnung) näher ausgestaltet. Das Solarpaket I und die NELEV-Änderungsverordnung sind bereits vom Kabinett beschlossen, gelten aber derzeit noch nicht.
    ${nextParagraph()}Es wird damit gerechnet, dass die genannten Änderungen im zweiten Quartal 2024 in Kraft treten werden. Das zentrale Register für Einheiten- und Komponentenzertifikate (ZEREZ) von FGW e. V. erfüllt alle Anforderungen eines solchen Registers. Wir sind davon überzeugt, dass hiervon unabhängig schon jetzt die Nutzung des Registers für alle Nutzer enorme Vorteile mit sich bringt und erhebliches Beschleunigungspotential beim Netzanschluss bietet.
  `,
    },
    register: {
      q: "Wer kann sich registrieren?",
      a: "Die Registrierung ist für Hersteller von Erzeugungsanlagen, Netzbetreiber, Zertifizierungsstellen sowie Planer, Errichter und Betreiber von Energieerzeugungsanlagen möglich.",
    },
    account: {
      q: "Wie erstelle ich ein Benutzerkonto?",
      a: "Die Erstellung eines Benutzerkontos erfolgt über die Registerkarte Registrierung auf der ZEREZ-Webseite. Folgen Sie den angegebenen Schritten, um Ihr Konto zu erstellen.",
    },
    login: {
      q: "Muss ich mich als Planer/Errichter/Betreiber anmelden?",
      a: "Eine Anmeldung ist nicht erforderlich, um das Register einzusehen. Erweiterte Funktionen sind nur nach erfolgreicher Registrierung verfügbar.",
    },
    upload: {
      q: "Wie lade ich ein Zertifikat hoch?",
      a: "Nach erfolgreicher Anmeldung können Sie über die Funktion Zertifikat hochladen das Zertifikat in digitaler Form in das ZEREZ-System übertragen.",
    },
    whoCanUpload: {
      q: "Wer darf Zertifikate hochladen?",
      a: "Nach erfolgreicher Registrierung können Hersteller und Zertifizierungsstelle über die Funktion Zertifikat hochladen ihre Zertifikate in das Register einstellen.",
    },
    forgotPassword: {
      q: "Ich habe mein Passwort vergessen. Wie kann ich es zurücksetzen?",
      a: "Unter der Anmeldeseite finden Sie die Option Kennwort vergessen. Folgen Sie den angegebenen Schritten, um Ihr Passwort zurückzusetzen.",
    },
    fees: {
      q: "Fallen Gebühren für die Registrierung im ZEREZ an?",
      a: "ZEREZ ist aufgrund der Vorgaben der Neuregelung durch die NELEV-Änderungsverordnung bisher kostenlos. Es entstehen keine Gebühren für die Registrierung und Nutzung der grundlegenden Funktionen des Registers.",
    },
    browser: {
      q: "Welcher Browser wird für ZEREZ empfohlen?",
      a: "ZEREZ ist für Google Chrome optimiert.",
    },
    units: {
      q: "Werden in ZEREZ Erzeugungsanlagen erfasst?",
      a: "In ZEREZ werden Einheiten- und Komponentenzertifikate von dezentralen Erzeugungsanlagen gelistet. Die Erfassung von Erzeugungsanlagen erfolgt im Marktstammdatenregister.",
    },
    users: {
      q: "Kann ein Unternehmen mehrere Benutzer registrieren?",
      a: "Die Registrierung erfolgt im Register. Die zuerst registrierte Person ist zugleich „Hauptadmin“ und kann weitere Teammitglieder hinzufügen.",
    },
    zerezIdPurpose: {
      q: "Wozu dient die ZEREZ-Registernummer (ZEREZ-ID)?",
      a: "Durch ZEREZ werden alle Einheitenzertifikate mit einer eindeutigen Registernummer verknüpft. Die ZEREZ-Registernummer (ZEREZ-ID) dient dazu, den Netzanschlussprozess effizienter zu gestalten. Zukünftig genügt die Angabe dieser Registernummer im Netzanschlussprozess und der Netzbetreiber kann die notwendigen Daten abrufen.",
    },
    zerezIdStructure: {
      q: "Wie ist die ZEREZ-Registernummer (ZEREZ-ID) aufgebaut?",
      a: `Die ZEREZ-Registernummer, zum Beispiel "ZE-R018-RV36-0001", setzt sich aus verschiedenen Teilen zusammen:
    ${BullitList({
      content: [
        "Präfix ZE, steht für das ZEREZ-Register",
        "R018: Kennung der Zertifizierungsstelle",
        "RV36: Kennung des Zertifikats",
        "0001: Laufende Nummerierung der Einheit/Komponente",
      ],
    })}`,
    },
    visibility: {
      q: "Wer darf welche Daten sehen?",
      a: `
      VDE-AR-N 4105:
      ${nextLine()} Das Deckblatt des Nachweisdokuments (VDE-AR-N 4105 - Anhang E.4/E.5) und das Zertifikat für den integrierten Netz- und Anlagenschutz bzw. der Prüfbericht zum NA-Schutz (VDE-AR-N 4105 - Anhang E.6/E.7) sind öffentlich einsehbar. Dies gilt auch für die Liste der Netzanschlusswerte.
      ${nextParagraph()} VDE-AR-N 4110/20/30:
      ${nextLine()} Das Deckblatt des Nachweisdokumentes [1.16] sowie bestimmte Daten sind öffentlich einsehbar. Registrierte Nutzer der Rolle Netzbetreiber haben zudem Zugriff auf die Liste der Netzanschlusswerte.
      `,
    },
  },
};
