import { bold, center, LegalNoteLink, nextLine, nextParagraph } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  databaseCardLabel: "Units & Certificates",
  databaseCardText: "Access the Public Register instantly to view publicly available unit certificates",
  days: "Days",
  faq: "FAQ",
  faqMessage: "Learn about topics that are likely to interest you.",
  headline: "ZEREZ - Central Register for Unit and Component Certificates",
  hours: "Hours",
  logInCardLabel: "Log In",
  logInCardText: "To access all features, simply log in with your username and password",
  mandatoryPhaseDescription: `${center(bold("Mandatory use of the register: February 1, 2025"))}`,
  minutes: "Minutes",
  otherDocuments: "Notes, templates and documents",
  otherDocumentsMessage: "Here you will find information, templates and documents for use with the register.",
  ourVision: "Our Vision",
  ourVisonMessage: "See who ZEREZ is primarily designed for and what the goals of this project are.",
  question: "What do you want to do?",
  registrationCardLabel: "Registration",
  registrationCardText: "Don`t have an account yet? Click here to create one.",
  seconds: "Seconds",
  countdownTo: "Countdown to the start of the mandatory use phase",
  linkToRegister: "Link to Register",
  linkToLogin: "Link to Login",
  linkToUnitsAndCertificates: "Link to Units and Certificates",
  linkToFaqs: "Link to FAQs",
  linkToOtherDocuments: "Link to Notes, Templates and Documents",
  textBlockOne: `
    Welcome to the Central Register for Unit and Component Certificates (ZEREZ) of FGW e. V.
    ${nextParagraph()}ZEREZ collects the unit and component certificates of all voltage levels in one place. These certificates on the electrotechnical properties of generating units and electrical components can be entered and retrieved here by authorized and interested parties.
    ${nextParagraph()}According to the ${LegalNoteLink({
    url: "https://www.gesetze-im-internet.de/nelev/BJNR165100017.html",
    ariaLabel: "Link to",
    label: "Electrotechnical Properties Verification Ordinance (NELEV)",
  })} of the Federal Ministry of Economics and Climate Protection (BMWK), market participants are obliged to use the central register from February 1, 2025 (see Section 7 (4) NELEV).
  `,
  textBlockTwo: ({ certificateCount }: { certificateCount: number | undefined }) => `
    ${
      certificateCount &&
      `${center(bold(`There are currently ${certificateCount} certificates on file in the register.`))}`
    }
    ${nextLine()}FGW e. V. has been entrusted with the operation of ZEREZ as an entrusted entity since July 17, 2024. The legal basis for the entrustment is Section 49d (3) sentence 1 of the Energy Industry Act (EnWG).
    ${nextParagraph()}Together with the users, the FGW would like to use the next few months to further test and continuously improve the functionality of the register. To this end, manufacturers and certification bodies already have the opportunity to voluntarily publish and maintain their unit certificates in the register. Network operators will soon be able to process all available certificates via an adapted data interface. However, network operators can already register today.
    ${nextParagraph()}${center(
    bold(`Support us and make the transition to the new system easier for yourself at the same time!`)
  )}
      ${nextLine()}${center(bold(`Do not wait until the use of ZEREZ is mandatory but register beforehand!`))}
    ${nextLine()}${center(bold(`Upload your certificates in the coming weeks!`))}
  `,
};
